/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { makeStyles } from '@material-ui/core';
import { func, string } from 'prop-types';
import React from 'react';
import { useAuth } from '../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';

const useStyles = makeStyles((theme) => ({
    signIn: {
        display: 'flex',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '12px',
        textTransform: 'uppercase',
        textAlign: 'center',
        textDecoration: 'none',
        color: theme.palette.cms?.mobileMenuTextColor || theme.palette.mobileMenuColor,
        marginTop: '3px',
        fontFamily: 'Lato',
    },
    signInMain: {
        width: 60,
        height: '100%',
        gridRow: 1,
        gridColumn: 4,
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const UserGuestIcon = ({ icon, title, track }) => {
    const classes = useStyles();
    const { loginByRedirect }  = useAuth();
    const handleClick = () => {
        track({
            eventCategory: 'MobileSignInLink',
            eventAction: 'Clicked Sign In',
            eventLabel: title,
        });
        loginByRedirect();
    };

    return (
        <div
            className={classes.signInMain}
            onClick={handleClick}
            role="presentation"
            id="loginButton"
        >
            <span className={classes.container}>
                <img src={icon} alt="guest Icon" aria-hidden="true" role="presentation" width="24" height="24" />
                <span
                    className={classes.signIn}
                >
                    {title}
                </span>
            </span>
        </div>
    );
};

UserGuestIcon.defaultProps = {
    icon: '',
    title: '',
    track: () => { },
};

UserGuestIcon.propTypes = {
    icon: string,
    title: string,
    track: func,
};

export default UserGuestIcon;
